const emailRegex = /^([\w_\.\-\+])+@([\w\-]+\.)+([\w]{2,10})+$/; // eslint-disable-line

function setupNewsletterForm() {
  var newsletter = document.querySelector("form[name=newsletter]");
  newsletter.addEventListener("submit", event => {
    event.preventDefault();

    var message = newsletter.querySelector("p");
    var button = newsletter.querySelector("button[type=submit]");
    var email = newsletter.querySelector("input[type=email]").value;

    // Validate input
    if (!email || !email.match(emailRegex)) {
      message.textContent = "Please enter a valid email address";
      return false;
    }

    // Show in progress state
    button.setAttribute("disabled", true);
    button.classList.add("loading");
    button.textContent = "Subscribing";

    // Subscribe email
    fetch("/api/newsletter", {
      method: "POST",
      body: JSON.stringify({
        email: email
      })
    })
      .then(resp => {
        // Done saving
        button.classList.remove("loading");

        if (resp.status === 500) {
          throw new Error(resp.statusText);
        }
        return (resp.status === 204 ? {} : resp.json());
      })
      .then(data => {
        // Show success message
        message.textContent = newsletter.getAttribute("data-success");
        button.textContent = "Subscribed";
      })
      .catch(e => {
        // Show error message and let the user try again
        message.textContent = newsletter.getAttribute("data-error");
        button.textContent = "Subscribe";
        button.removeAttribute("disabled");
        console.log("Newsletter error", e); // eslint-disable-line
      });
  });
}

setupNewsletterForm();
